import { render, staticRenderFns } from "./doohickey.vue?vue&type=template&id=166266ad&scoped=true"
import script from "./doohickey.vue?vue&type=script&lang=js"
export * from "./doohickey.vue?vue&type=script&lang=js"
import style0 from "./doohickey.vue?vue&type=style&index=0&id=166266ad&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "166266ad",
  null
  
)

export default component.exports